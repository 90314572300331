import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
const Cancellation = () => {
    const { t } = useTranslation()

    return (
        <div>
            <Box>
                <Typography variant="h5" >{t("Registration.Cancellation.Name")}</Typography>
                <Box component={'ol'} >
                    <li>
                        <Typography>
                            {t("Registration.Cancellation.title1")}
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            {t("Registration.Cancellation.title2")}
                        </Typography>
                    </li>
                </Box>
            </Box>
        </div>
    )
}

export default Cancellation
