import React from 'react'
import { useTranslation } from 'react-i18next'

const Table = () => {
    const { t } = useTranslation()
    return (
        <div>
            <table className='table' border="1" >
                <thead>
                    <th colSpan={2} >{t('Registration.Table.RegistrationType')}</th>
                    <th colSpan={2} >{t('Registration.Table.EarlyRegistrationRates')}</th>
                    <th colSpan={2} >{t('Registration.Table.RegularRegistrationRates')}</th>
                    <th colSpan={2} >{t('Registration.Table.LateRegistrationRates')}</th>
                    <th colSpan={2} >{t('Registration.Table.OnSiteRegistrationRates')}</th>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan={2} ></td>
                        <td colSpan={4} >{t('Registration.Table.row1.col1')}</td>
                        <td colSpan={4}>{t('Registration.Table.row1.col2')}</td>
                    </tr>
                    <tr>
                        <td colSpan={2} >{t('Registration.Table.row2.col1')}</td>
                        <td colSpan={2}>{t('Registration.Table.row2.col2')}</td>
                        <td colSpan={2}>{t('Registration.Table.row2.col3')}</td>
                        <td colSpan={2}>{t('Registration.Table.row2.col4')}</td>
                        <td colSpan={2}>{t('Registration.Table.row2.col5')}</td>
                    </tr>
                    <tr>
                        <td colSpan={2} >{t('Registration.Table.row3.col1')}</td>
                        <td>{t('Registration.Table.row3.col2')}</td>
                        <td>{t('Registration.Table.row3.col3')}</td>
                        <td>{t('Registration.Table.row3.col4')}</td>
                        <td>{t('Registration.Table.row3.col5')}</td>
                        <td>{t('Registration.Table.row3.col6')}</td>
                        <td>{t('Registration.Table.row3.col7')}</td>
                        <td>{t('Registration.Table.row3.col8')}</td>
                        <td>{t('Registration.Table.row3.col9')}</td>
                    </tr>
                    <tr>
                        <td colSpan={2} >{t('Registration.Table.row5.col1')}</td>
                        <td>{t('Registration.Table.row5.col2')}</td>
                        <td>{t('Registration.Table.row5.col3')}</td>
                        <td>{t('Registration.Table.row5.col4')}</td>
                        <td>{t('Registration.Table.row5.col5')}</td>
                        <td>{t('Registration.Table.row5.col6')}</td>
                        <td>{t('Registration.Table.row5.col7')}</td>
                        <td>{t('Registration.Table.row5.col8')}</td>
                        <td>{t('Registration.Table.row5.col9')}</td>
                    </tr>
                    <tr>
                        <td colSpan={2}>{t('Registration.Table.row4.col1')}</td>
                        <td>{t('Registration.Table.row4.col2')}</td>
                        <td>{t('Registration.Table.row4.col3')}</td>
                        <td>{t('Registration.Table.row4.col4')}</td>
                        <td>{t('Registration.Table.row4.col5')}</td>
                        <td>{t('Registration.Table.row4.col6')}</td>
                        <td>{t('Registration.Table.row4.col7')}</td>
                        <td>{t('Registration.Table.row4.col8')}</td>
                        <td>{t('Registration.Table.row4.col9')}</td>
                        {/* <td>{t('Registration.Table.row4.col10')}</td> */}
                    </tr>
                    <tr>
                        <td colSpan={10} >{t('Registration.Table.row6.col1')}</td>
                    </tr>
                    <tr>
                        <td colSpan={2} >{t('Registration.Table.row7.col1')}</td>
                        <td>{t('Registration.Table.row7.col2')}</td>
                        <td>{t('Registration.Table.row7.col3')}</td>
                        <td>{t('Registration.Table.row7.col4')}</td>
                        <td>{t('Registration.Table.row7.col5')}</td>
                        <td colSpan={4} >{t('Registration.Table.row7.col6')}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default Table
