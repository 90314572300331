import React from 'react'
import Contacts from '../components/Contacts/Contacts'

const ContactsPage = () => {
    return (
        <div>
            <Contacts />
        </div>
    )
}

export default ContactsPage
