import { Box, Container, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Organizers from './Organizers'
import Sponsors from './Sponsors'
import Venue from './Venue'
import Generallocation from './Generallocation'
import ImportantDates from './ImportantDates'
const GeneralInformation = () => {
    const { t } = useTranslation()
    return (
        <div>
            <Box>
                <Container>
                    {/* Organizers: */}
                    <Box>
                        <Organizers />
                        {/* Sponsors */}
                        <Sponsors />
                        {/* The Venue: */}
                        <Venue />
                        {/* General location map */}
                        <Generallocation />
                        {/* Language: */}
                        <Box>
                            <Typography sx={{ fontWeight: 'bold' }} >{t('GeneralInformation.Language')}</Typography>
                            <Typography sx={{ marginInlineStart: 5 }} >{t('GeneralInformation.LanguageDescriptions')}</Typography>
                        </Box>
                        {/* Important dates: */}
                        <ImportantDates />
                    </Box>
                </Container>
            </Box>
        </div>
    )
}

export default GeneralInformation
