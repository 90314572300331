import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ExampleDoc from './../../assets/Registration form.docx'

export default function SelectSmall() {
    const [setAge] = React.useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };
    const { t } = useTranslation()
    return (
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small-label">Form</InputLabel>
            <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                label="Form"
                onChange={handleChange}>
                <MenuItem><Link
                    to="/Registration"
                    style={{
                        color: "#000",
                        textDecoration: "none",
                        fontSize: 15,
                        fontWeight: "bold",
                    }}>
                    {t('linksPages.Registration')}
                </Link></MenuItem>
                <MenuItem>
                    <a href={ExampleDoc}
                        download="MyExampleDoc"
                        target='_blank'
                        rel="noreferrer"
                        style={{ textDecoration: 'none', color: '#000000', fontWeight: 'bold' }}
                    >
                        {t('linksPages.RegistrationForm')}
                    </a>
                </MenuItem>
            </Select>
        </FormControl>
    );
}