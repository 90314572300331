import React from 'react'
import Registration from '../components/Registration/Registration'
import Slider from "../components/Slider/Slider";
import slide_1 from './../assets/Slider/16.jpg'
import slide_2 from './../assets/Slider/17.jpg'
import slide_3 from './../assets/Slider/18.jpg'
import slide_4 from './../assets/Slider/19.jpg'
import slide_5 from './../assets/Slider/20.jpg'
const RegistrationPage = () => {
    return (
        <>
            <Slider
                path_1={slide_1}
                path_2={slide_2}
                path_3={slide_3}
                path_4={slide_4}
                path_5={slide_5}
            />
            <div style={{ padding: '30px 0' }} >
                <Registration />
            </div>
        </>
    )
}

export default RegistrationPage
