import { Box, Container, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Card from './Card'
import img1 from './../../assets/Home/n1.jpg'
import img2 from './../../assets/Home/n12.jpg'
import img3 from './../../assets/Home/n2.jpg'
import img4 from './../../assets/Home/n22.jpg'
import img5 from './../../assets/Home/n3.jpg'
import img6 from './../../assets/Home/n33.jpg'
import img7 from './../../assets/Home/n4.jpg'
import img8 from './../../assets/Home/n44.jpg'
// import img9 from './../../assets/Home/7.jpg'
// import img10 from './../../assets/Home/16.jpg'
// import img11 from './../../assets/Home/24.jpg'
// import img12 from './../../assets/Home/17.jpg'
const Accommodation = () => {
    const { t } = useTranslation()
    return (
        <div style={{ padding: '30px 0' }} >
            <Container>
                <Box>
                    <Typography variant='h4' sx={{ color: '#c00000', fontWeight: 'bold', textAlign: 'center' }} > {t("Accommodation.Name")} </Typography>
                    <a href="https://maps.app.goo.gl/NRrke6Rt8ZU6Qz918" target="_blank" rel="noopener noreferrer">
                        <Typography sx={{ fontWeight: 'bold', textAlign: 'center' }} > {t("Accommodation.Map")} </Typography>
                    </a>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11612.5495508677!2d32.86185182966197!3d24.060923098013387!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1436636b4e420d19%3A0xe30e5705ec051ec1!2sSeheil%20Island!5e1!3m2!1sen!2seg!4v1702201483513!5m2!1sen!2seg"
                        width="100%"
                        height="450"
                        title='nup'
                        // style="border:0;"
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"></iframe>
                    {/* <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14574.261788752843!2d32.876954649999995!3d24.046384499999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1436631eeeccd29d%3A0xdedc85006ad7f4!2sNagaa%20Al%20Karur%2C%20Sheyakhah%20Thaneyah%2C%20Qism%20Aswan%2C%20Aswan%20Governorate!5e0!3m2!1sen!2seg!4v1702194245671!5m2!1sen!2seg"
                        title='nup'
                        height="450"
                        width="100%"
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                    {/* <iframe src="https://www.google.com/maps/d/embed?mid=1wY3x1uo9xmYchPaGVrWgkWHv4UIw0Os&ehbc=2E312F"
                    title='nub'
                    width="100%"
                    height="480"></iframe> */}
                    <Typography variant='h5' sx={{ fontWeight: 'bold', mt: 2 }} > {t("Accommodation.IslandHotels")} </Typography>
                    <Box component={'ol'} >
                        <Card
                            hotel={t("Accommodation.BenBen")}
                            pathLocation={'https://maps.app.goo.gl/wpHJEkJf6H4UqWCD9'}
                            path1={img1}
                            path2={img2}
                        // WebsiteUrl={'https://dharahotels.com/en/aswan/benben-hotel/'}
                        // Website={`${t("Accommodation.Website")}https://dharahotels.com/en/aswan/benben-hotel/`}
                        />
                        <Card
                            hotel={t("Accommodation.Noprea")}
                            pathLocation={'https://maps.app.goo.gl/ixQa9gnVxNZdYvYPA'}
                            path1={img3}
                            path2={img4}
                        />
                        <Card
                            hotel={t("Accommodation.HeissaArtie")}
                            pathLocation={'https://maps.app.goo.gl/1Z7f8AqM3y7Shd5F9'}
                            path1={img5}
                            path2={img6}
                        />
                        <Card
                            hotel={t("Accommodation.MrHashem")}
                            pathLocation={'https://maps.app.goo.gl/wa6bfmjeUuYEKPCN9'}
                            path1={img7}
                            path2={img8}
                        />

                        {/* <Card
                            hotel={t("Accommodation.HeissaHostel")}
                            pathLocation={'https://maps.app.goo.gl/bz4FRUNoBDzLYusx8'}
                            path1={img9}
                            path2={img10}
                        />
                        <Card
                            hotel={t("Accommodation.Aigyjelika")}
                            pathLocation={'https://maps.app.goo.gl/5hnK5sFHyYCRDb6aA'}
                            path1={img11}
                            path2={img12}
                        /> */}
                    </Box>
                </Box>
            </Container>
        </div>
    )
}

export default Accommodation
