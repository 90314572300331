import React from "react";
import Header from "../components/Header/Header";
import Slider from "../components/Slider/Slider";
import slide_1 from './../assets/Slider/1.jpg'
import slide_2 from './../assets/Slider/2.jpg'
import slide_3 from './../assets/Slider/3.jpg'
import slide_4 from './../assets/Slider/4.jpg'
import slide_5 from './../assets/Slider/5.jpg'
import path1 from './../assets/WhatsApp Image 2023-10-18 at 14.45.01_d4114ad8.jpg'
import path2 from './../assets/WhatsApp Image 2023-10-18 at 15.13.14_095d61ab.jpg'
import GeneralInformation from "../components/GeneralInformation/GeneralInformation";
import Abstracts from "../components/Abstracts/Abstracts";
import Topics from "../components/Topics/Topics";
import Registration from "../components/Registration/Registration";
import Committees from "../components/Committees/Committees";
import Accommodation from "../components/Accommodation/Accommodation";
import Contacts from "../components/Contacts/Contacts";
import { Box, CardMedia } from "@mui/material";
function Home() {

    return (
        <>
            <Slider
                path_1={slide_1}
                path_2={slide_2}
                path_3={slide_3}
                path_4={slide_4}
                path_5={slide_5}
            />
            <Header />
            <Box sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column' } }} >
                <CardMedia
                    className='card2'
                    component={'img'}
                    src={path1}
                    alt='logo'
                    sx={{
                        margin: 'auto',
                        mb: 2,
                        mt: 2,
                        width: { md: '45%', xs: '90%' },
                        height: '20pc',
                        borderRadius: '20px'
                    }}
                />
                <CardMedia
                    className='card2'
                    component={'img'}
                    src={path2}
                    alt='logo'
                    sx={{
                        margin: 'auto',
                        mb: 2,
                        mt: 2,
                        width: { md: '45%', xs: '90%' },
                        height: '20pc',
                        borderRadius: '20px'
                    }}
                />
            </Box>
            <GeneralInformation />
            {/* <NubiaEgypt /> */}
            <Topics />
            <Abstracts />
            <Registration />
            <Committees />
            <Accommodation />
            <Contacts />
        </>
    );
}

export default Home;
