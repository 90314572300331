import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
// import { contact } from '../../constants'

const ContactData = () => {
    const { t } = useTranslation()
    return (
        <div>
            <Box sx={{ direction: 'ltr' }} >
                <Typography sx={{ fontWeight: 'bold', color: '#FFF' }} variant='h6' >contact</Typography>
                <Box
                    sx={{

                        gap: 2,
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "left",
                        padding: "20px",
                    }}>
                    <a
                        href="tel:+201002603250"
                        style={{
                            color: "#FFF",
                            textDecoration: "none",
                            fontSize: 15,
                            fontWeight: "bold",
                        }}>
                        <Typography >+201002603250</Typography>
                    </a>
                    <a style={{ textDecoration: 'none', color: '#FFF' }}
                        href="mailto:info@nubia2024.com">
                        <Typography sx={{ fontWeight: 'bold' }}>
                            {t("Contacts.email")}
                        </Typography>
                    </a>
                    <a style={{ textDecoration: 'none', color: '#FFF' }}
                        href="www.nubia2024.com">
                        <Typography sx={{ fontWeight: 'bold' }}>
                            {'www.nubia2024.com'}
                        </Typography>
                    </a>
                    <Box sx={{ display: 'flex', flexDirection: { md: 'column', xs: 'column' } }} >
                        <a style={{ textDecoration: 'none', color: '#FFF' }}
                            href="mailto:Torab.magdy@gmail.com">
                            <Typography sx={{ fontWeight: 'bold' }}>
                                Torab.magdy@gmail.com
                            </Typography>
                        </a>
                        <a style={{ textDecoration: 'none', color: '#FFF' }}
                            href="mailto:magdytorab@art.dmu.edu.eg">
                            <Typography sx={{ fontWeight: 'bold' }}>
                                magdytorab@art.dmu.edu.eg
                            </Typography>
                        </a>
                    </Box>
                </Box>
            </Box>
        </div>
    )
}

export default ContactData
