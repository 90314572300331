import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

const Venue = () => {
    const { t } = useTranslation()
    return (
        <div>
            <Box style={box} >
                <Typography sx={{ fontWeight: 'bold' }} >{t('GeneralInformation.TheVenue')}</Typography>
                <Typography sx={{ fontWeight: 'bold', color: '#c00000', textAlign: 'center' }} variant='h5' >{t('GeneralInformation.VenueTitle')}</Typography>
                <Typography>{t('GeneralInformation.VenueDescriptions')}</Typography>
            </Box>
        </div>
    )
}
const box = {
    display: 'flex',
    flexDirection: 'column',
    gap: '25px',
    margin: '15px'
}
export default Venue
