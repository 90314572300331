import { Box, CardMedia, Container, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import logo from './../../assets/Logo/logo.png'
const Contacts = () => {
    const { t } = useTranslation()
    return (
        <div style={{ padding: '25px 0' }} >
            <Container>
                <Box>
                    <Typography sx={{
                        color: '#c00000',
                        textAlign: 'center',
                        fontWeight: 'bold'
                    }}
                        variant='h3' > {t("Contacts.Name")}
                    </Typography>
                    <CardMedia
                        component={'img'}
                        src={logo}
                        alt='logo'
                        sx={{
                            width: 'fit-content',
                            height: '14pc',
                            mb: 2
                        }}
                    />
                    <Typography sx={{ fontWeight: 'bold' }}>
                        {t("Contacts.Symposium")}
                    </Typography>
                    <Typography sx={{ fontWeight: 'bold' }}>
                        {t("Contacts.ISLL-7")}
                    </Typography>
                    <Typography sx={{ fontWeight: 'bold' }}>
                        {t("Contacts.Adaptation")}
                    </Typography>
                    <Typography sx={{ fontWeight: 'bold' }}>
                        {t("Contacts.Nubia")}
                    </Typography>
                    <Typography sx={{ fontWeight: 'bold' }}>
                        {t("Contacts.NubiaIslands")}
                    </Typography>
                    <a href="mailto:info@nubia2024.com">
                        <Typography sx={{ fontWeight: 'bold' }}>
                            {t("Contacts.email")}
                        </Typography>
                    </a>
                    <a href="www.nubia2024.com">
                        <Typography sx={{ fontWeight: 'bold' }}>
                            {t("Contacts.website")}
                        </Typography>
                    </a>
                    <Typography sx={{ fontWeight: 'bold' }}>
                        {t("Contacts.Symposiums")}
                    </Typography>
                    <Typography sx={{ fontWeight: 'bold' }}>
                        {t("Contacts.TORAB")}
                    </Typography>
                    <Typography sx={{ fontWeight: 'bold' }}>
                        {t("Contacts.Damanhour")}
                    </Typography>
                    <Typography sx={{ fontWeight: 'bold' }}>
                        {t("Contacts.President")}
                    </Typography>
                    <Typography sx={{ fontWeight: 'bold' }}>
                        {t("Contacts.EditorInChief")}
                    </Typography>
                    <Typography sx={{ fontWeight: 'bold' }}>
                        {t("Contacts.E-mails")}
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column' } }} >
                        <a href="mailto:Torab.magdy@gmail.com">
                            <Typography sx={{ fontWeight: 'bold' }}>
                                Torab.magdy@gmail.com
                            </Typography>
                        </a>
                        &
                        <a href="mailto:magdytorab@art.dmu.edu.eg">
                            <Typography sx={{ fontWeight: 'bold' }}>
                                magdytorab@art.dmu.edu.eg
                            </Typography>
                        </a>
                    </Box>
                    <Typography sx={{ fontWeight: 'bold' }}>
                        {t("Contacts.Telephone")}
                    </Typography>
                </Box>
            </Container>
        </div>
    )
}

export default Contacts
