import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
const Nonrefundable = () => {
    const { t } = useTranslation()

    return (
        <div>
            <Box>
                <Typography variant="h5" >{t("Registration.nonrefundable.Name")}</Typography>
                <Box component={'ol'} >
                    <li>
                        <Typography>
                            {t("Registration.nonrefundable.title1")}
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            {t("Registration.nonrefundable.title2")}
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            {t("Registration.nonrefundable.title3")}
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            {t("Registration.nonrefundable.title4")}
                        </Typography>
                    </li>
                </Box>
            </Box>
        </div>
    )
}

export default Nonrefundable
