import { Box, Typography } from '@mui/material'
import React from 'react'
// import { footerLinks } from '../../constants'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
const PathPages = () => {
    const { t } = useTranslation()

    return (
        <div>
            <Box>
                <Typography sx={{ fontWeight: 'bold', color: '#FFF' }} variant='h6' >pages</Typography>
                <Box
                    sx={{

                        gap: 2,
                        display: "flex",
                        flexWrap: "wrap",
                        textAlign: "left",
                        padding: "20px",
                    }}>
                    <Link
                        to="/"
                        style={{
                            color: "#fff",
                            textDecoration: "none",
                            fontSize: 14,
                            fontWeight: "bold",
                        }}>
                        {t('linksPages.home')}
                    </Link>
                    <Link
                        to="/General-information"
                        style={{
                            color: "#fff",
                            textDecoration: "none",
                            fontSize: 14,
                            fontWeight: "bold",
                        }}>
                        {t('linksPages.GeneralInformation')}
                    </Link>
                    <Link
                        to="/Nubia-Egypt"
                        style={{
                            color: "#fff",
                            textDecoration: "none",
                            fontSize: 14,
                            fontWeight: "bold",
                        }}>
                        {t('linksPages.Nubia-Egypt')}
                    </Link>
                    <Link
                        to="/Topics"
                        style={{
                            color: "#fff",
                            textDecoration: "none",
                            fontSize: 14,
                            fontWeight: "bold",
                        }}>
                        {t('linksPages.Topics')}
                    </Link>
                    <Link
                        to="/Abstracts"
                        style={{
                            color: "#fff",
                            textDecoration: "none",
                            fontSize: 14,
                            fontWeight: "bold",
                        }}>
                        {t('linksPages.Abstracts')}
                    </Link>
                    <Link
                        to="/Committees"
                        style={{
                            color: "#fff",
                            textDecoration: "none",
                            fontSize: 14,
                            fontWeight: "bold",
                        }}>
                        {t('linksPages.Committees')}
                    </Link>
                    <Link
                        to="/Accommodation"
                        style={{
                            color: "#fff",
                            textDecoration: "none",
                            fontSize: 14,
                            fontWeight: "bold",
                        }}>
                        {t('linksPages.Accommodation')}
                    </Link>
                    <Link
                        to="/Contacts"
                        style={{
                            color: "#fff",
                            textDecoration: "none",
                            fontSize: 14,
                            fontWeight: "bold",
                        }}>
                        {t('linksPages.Contacts')}
                    </Link>
                </Box>
            </Box>
        </div>
    )
}

export default PathPages
