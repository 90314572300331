import React from "react";
import Carousel from "react-material-ui-carousel";
import { CardMedia } from "@mui/material";
function Slider({ path_1, path_2, path_3, path_4, path_5 }) {
    return (
        <>
            <Carousel
                navButtonsAlwaysVisible={true}
                sx={{
                    width: "90%",
                    margin: 'auto',
                    height: { md: "500px", xs: "300px" }
                }}
            >
                <CardMedia
                    sx={{ height: { md: "500px", xs: "300px" } }}

                    component="img"
                    alt="green iguana"
                    src={path_1}
                    style={{

                        width: "100%",
                        objectFit: "fill",
                        borderBottomLeftRadius: "20px",
                        borderBottomRightRadius: "20px",
                    }}
                />
                <CardMedia
                    sx={{ height: { md: "500px", xs: "300px" } }}
                    component="img"
                    alt="green iguana"
                    src={path_2}
                    style={{

                        width: "100%",
                        objectFit: "fill",
                        borderBottomLeftRadius: "20px",
                        borderBottomRightRadius: "20px",
                    }}
                />
                <CardMedia
                    sx={{ height: { md: "500px", xs: "300px" } }}
                    component="img"
                    alt="green iguana"
                    src={path_3}
                    style={{

                        width: "100%",
                        objectFit: "fill",
                        borderBottomLeftRadius: "20px",
                        borderBottomRightRadius: "20px",
                    }}
                />
                <CardMedia
                    sx={{ height: { md: "500px", xs: "300px" } }}
                    component="img"
                    alt="green iguana"
                    src={path_4}
                    style={{

                        width: "100%",
                        objectFit: "fill",
                        borderBottomLeftRadius: "20px",
                        borderBottomRightRadius: "20px",
                    }}
                />
                <CardMedia
                    sx={{ height: { md: "500px", xs: "300px" } }}
                    component="img"
                    alt="green iguana"
                    src={path_5}
                    style={{

                        width: "100%",
                        objectFit: "fill",
                        borderBottomLeftRadius: "20px",
                        borderBottomRightRadius: "20px",
                    }}
                />
            </Carousel>
        </>
    );
}

export default Slider;
