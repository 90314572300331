import React from 'react'
import GeneralInformation from '../components/GeneralInformation/GeneralInformation'
import Slider from "../components/Slider/Slider";
import slide_1 from './../assets/Slider/6.jpg'
import slide_2 from './../assets/Slider/7.jpg'
import slide_3 from './../assets/Slider/8.jpg'
import slide_4 from './../assets/Slider/9.jpg'
import slide_5 from './../assets/Slider/10.jpg'

const GeneralPage = () => {
    return (
        <>
            <Slider
                path_1={slide_1}
                path_2={slide_2}
                path_3={slide_3}
                path_4={slide_4}
                path_5={slide_5}
            />
            <div style={{ padding: '30px 0' }} >
                <GeneralInformation />
            </div>
        </>
    )
}

export default GeneralPage
