import React from 'react'
import Abstracts from '../components/Abstracts/Abstracts'
import Slider from "../components/Slider/Slider";
import slide_1 from './../assets/Slider/11.jpg'
import slide_2 from './../assets/Slider/12.jpg'
import slide_3 from './../assets/Slider/13.jpg'
import slide_4 from './../assets/Slider/14.jpg'
import slide_5 from './../assets/Slider/15.jpg'
const AbstractsPage = () => {
    return (
        <>
            <Slider
                path_1={slide_1}
                path_2={slide_2}
                path_3={slide_3}
                path_4={slide_4}
                path_5={slide_5}
            />
            <div style={{ padding: '30px 0' }} >
                <Abstracts />
            </div>
        </>
    )
}

export default AbstractsPage
