import { Box, CardMedia, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import map from './../../assets/orgInfor/genMap.png'

const Generallocation = () => {
    const { t } = useTranslation()

    return (
        <div>
            <Box style={box} textAlign={'center'} >
                <CardMedia
                    component={'img'}
                    src={map}
                    alt='logo'

                    sx={{
                        margin: 'auto', width: { md: '500px', xs: '100%' },
                        height: '30pc',
                        objectFit: 'fill',
                        mb: 2,
                        mt: 2
                    }}
                />
                <Typography sx={{ fontWeight: 'bold' }} >{t('GeneralInformation.GeneralLocationMap')}</Typography>
            </Box>
        </div>
    )
}
const box = {
    display: 'flex',
    flexDirection: 'column',
}
export default Generallocation
