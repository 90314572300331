import { Box, CardMedia, Typography } from '@mui/material'
import React from 'react'
import sponsors from './../../assets/orgInfor/organ.jpg'
import gig from './../../assets/orgInfor/GIG.jpg'
import glop from './../../assets/glop.jpg'
import log2 from './../../assets/log2.jpg'
import { useTranslation } from 'react-i18next'

const Sponsors = () => {
    const { t } = useTranslation()

    return (
        <div>
            <Box>
                <Typography sx={{ fontWeight: 'bold' }} variant='h5' >{t('GeneralInformation.ScientificAuspices')}</Typography>
                <CardMedia
                    component={'img'}
                    src={glop}
                    alt='logo'
                    sx={{
                        margin: 'auto',

                        width: { md: '25%', xs: '100%' },
                        objectFit: 'fill',
                        // height: '12pc',
                        mb: 2,
                        borderRadius: '25px'
                    }}
                />
                <Typography sx={{ fontWeight: 'bold' }} variant='h5' >{t('GeneralInformation.Sponsors')}</Typography>
                <CardMedia
                    component={'img'}
                    src={sponsors}
                    alt='logo'

                    sx={{
                        margin: 'auto',
                        width: '100%',
                        mb: 2
                    }}
                />
                <Box sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column' }, justifyContent: 'space-between', gap: 4 }} >
                    <Box sx={{ width: { md: '50%', xs: '100%' }, boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", p: 1, border: '1px solid #ccc' }} >
                        <CardMedia
                            component={'img'}
                            src={log2}
                            alt='logo'
                            sx={{
                                margin: 'auto',

                                width: { md: '250px', xs: '100%' },
                                objectFit: 'fill',
                                // height: '10pc',
                                mb: 2,
                                borderRadius: '25px'
                            }}
                        />
                        <Box>
                            <Typography>Europomice S.r.l.</Typography>
                            <Typography>
                                <a href="http://www.europomice.com">http://www.europomice.com</a><br />
                                <a href="mailto:info@europomice.it">info@europomice.it</a>
                            </Typography>
                            <Typography>HEADQUARTER:</Typography>
                            <Typography>Via Napo Torriani, 1   - 20124 Milano (Italy)</Typography>
                            <Typography>Tel: <a href="tel:+390266981471">+390266981471</a> </Typography>
                            <Typography>TAX ID: 01086340906</Typography>
                            <Typography>VAT: IT09794450156</Typography>
                        </Box>
                    </Box>
                    <Box sx={{ width: { md: '50%', xs: '100%' }, boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", p: 1, border: '1px solid #ccc' }} >
                        <CardMedia
                            component={'img'}
                            src={gig}
                            alt='logo'

                            sx={{
                                margin: 'auto',

                                width: { md: '200px', xs: '100%' },
                                objectFit: 'fill',
                                // height: '8pc',
                                mb: 2
                            }}
                        />
                        <Box>
                            <Typography>Globe Imagine for Geoinformatics (GIG)</Typography>
                            <Typography>
                                <a href="http://www.globeimagine.com">http://www.globeimagine.com</a><br />
                                <a href="mailto:info@globeimagine.com">info@globeimagine.com</a>
                            </Typography>
                            <Typography>HEADQUARTER:</Typography>
                            <Typography>Ryhana Plaza, Tower C, 2nd floor, unit 3, Zahraa Al-Maadi, Cairo, Egypt</Typography>
                            <Typography>Tel: (+2) 25197532 <a href="tel:+201274009030">+201274009030</a> </Typography>
                            <Typography>TAX ID: 729-007-154</Typography>
                            <Typography>Commercial Registry: 81754</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </div>
    )
}
export default Sponsors
