import { Box, CardMedia, Typography } from '@mui/material'
import React from 'react'
import './NubiaCard.css'
const SectionVenue = ({ title, Descriptions, SupDescription1, path1, path2 }) => {
  return (
    <div>
      <Box>
        <Typography my={2} >
          <span style={{ fontWeight: 'bold' }} >{title} </span>
          {Descriptions}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column' } }} >
          <CardMedia
            className='card2'
            component={'img'}
            src={path1}
            alt='logo'
            sx={{
              margin: 'auto',
              mb: 2,
              mt: 2,
              width: { md: '45%', xs: '90%' },
              height: '20pc',
              borderRadius: '20px'
            }}
          />
          <CardMedia
            className='card2'
            component={'img'}
            src={path2}
            alt='logo'
            sx={{
              margin: 'auto',
              mb: 2,
              mt: 2,
              width: { md: '45%', xs: '90%' },
              height: '20pc',
              borderRadius: '20px'
            }}
          />
        </Box>
        <Typography my={2} >
          {SupDescription1}
        </Typography>
      </Box>
    </div>
  )
}

export default SectionVenue
