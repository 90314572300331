import { Box, CardMedia, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import './Card.css'
const Card = ({ hotel, pathLocation, WebsiteUrl, Website, path1, path2 }) => {
    const { t } = useTranslation()
    return (
        <div>
            <li>
                <Typography sx={{ fontWeight: 'bold' }} > {hotel} </Typography>
                <Box sx={{ display: 'flex', flexDirection: { md: 'column', xs: 'column' }, width: { md: '100%', xs: '80%' } }} >
                    <a href={pathLocation} target="_blank" rel="noopener noreferrer"
                        style={{ color: "#c00000" }}
                    >
                        <Typography sx={{ fontSize: { md: '18px', xs: '12px' } }} >

                            {t("Accommodation.Location")} {pathLocation}
                        </Typography>
                    </a>
                    <br />
                    <a href={WebsiteUrl} target="_blank" rel="noopener noreferrer"
                        style={{ color: "#c00000" }}
                    >
                        <Typography sx={{ fontSize: { md: '18px', xs: '12px' } }} >

                            {Website}
                        </Typography>
                    </a>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column' } }} className='cards' >
                    <CardMedia
                        className="card card-one"
                        component={'img'}
                        src={path1}
                        alt='logo'
                        sx={{
                            margin: 'auto',
                            mb: 2,
                            mt: 2,
                            width: { md: '45%', xs: '90%' },
                            height: '20pc',
                            borderRadius: '20px'
                        }}
                    />
                    <CardMedia
                        className="card card-two"
                        component={'img'}
                        src={path2}
                        alt='logo'
                        sx={{
                            margin: 'auto',
                            mb: 2,
                            mt: 2,
                            width: { md: '45%', xs: '90%' },
                            height: '20pc',
                            borderRadius: '20px'
                        }}
                    />
                </Box>
            </li>
        </div>
    )
}

export default Card
