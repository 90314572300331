import React from 'react'
import Committees from '../components/Committees/Committees'
import Slider from "../components/Slider/Slider";
import slide_1 from './../assets/Slider/22.jpg'
import slide_2 from './../assets/Slider/21.jpg'
import slide_3 from './../assets/Slider/23.jpg'
import slide_4 from './../assets/Slider/24.jpg'
import slide_5 from './../assets/Slider/25.jpg'
const CommitteesPage = () => {
    return (
        <>
            <Slider
                path_1={slide_1}
                path_2={slide_2}
                path_3={slide_3}
                path_4={slide_4}
                path_5={slide_5}
            />
            <div style={{ padding: '30px 0' }} >
                <Committees />
            </div>
        </>
    )
}

export default CommitteesPage
