import { Box, Container, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import TableScientific from './TableScientific'
import TableOrganizing from './TableOrganizing'

const Committees = () => {
    const { t } = useTranslation()
    return (
        <div>
            <Container>
                <Typography
                    variant='h4'
                    sx={{
                        textAlign: 'center',
                        fontWeight: 'bold',
                        color: '#c00000',
                        mt: 2
                    }} >
                    {t("Committees.Name")}
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }} >
                    <Typography variant='h5' sx={{
                        fontWeight: 'bold',
                        color: '#c00000',
                        mt: 1
                    }}>{t("Committees.Scientific")}</Typography>
                    <TableScientific />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }} >
                    <Typography variant='h5' sx={{
                        fontWeight: 'bold',
                        color: '#c00000',
                        mt: 1
                    }}>{t("Committees.Organization")}</Typography>
                    <TableOrganizing />
                </Box>
            </Container>
        </div>
    )
}

export default Committees
