import { Box, CardMedia, Container, Typography } from '@mui/material'
import React from 'react'
import logo from './../../assets/Logo/logo.png'
import { useTranslation } from 'react-i18next'
const Header = () => {
    const { t } = useTranslation()
    return (
        <div>
            <Box p={2} my={3} style={box} >
                <Container>
                    <CardMedia
                        component={'img'}
                        src={logo}
                        alt='logo'

                        sx={{
                            margin: 'auto', width: 'fit-content',
                            height: '14pc',
                            mb: 2
                        }}
                    />
                    <Box style={containerTypography} >
                        <Typography variant='h5' sx={{ fontWeight: 'bold', }} >{t('HomeSection.Symposium')}</Typography>
                        <Typography sx={{ fontWeight: 'bold', color: '#070738' }} >{t('HomeSection.Adaptation')}</Typography>
                        <Typography sx={{ fontWeight: 'bold', color: '#c00000' }} >{t('HomeSection.Nubia')}</Typography>
                        <Typography variant='h5' sx={{ fontWeight: 'bold', }} >{t('HomeSection.Egypt17')}</Typography>
                    </Box>
                    <Box>
                        <Typography sx={{ fontWeight: 'bold' }} >{t('HomeSection.Name') + ':'}</Typography>
                        <Typography>{t('HomeSection.Description')}</Typography>
                    </Box>
                </Container>
            </Box>
        </div>
    )
}
const containerTypography = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    gap: 10
}
const box = {
    display: 'flex',
    flexDirection: 'column',
    gap: '25px'
}

export default Header
