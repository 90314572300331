import { Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

const TableScientific = () => {
    const { t } = useTranslation()
    return (
        <div>
            <table border="1" style={{ width: '100%', }}>
                <thead>
                    <th>{t('Committees.TableScientific.Name')}</th>
                    <th>{t('Committees.TableScientific.Institution')}</th>
                </thead>
                <tbody>
                    <tr>
                        <td>{t('Committees.TableScientific.row1.col1')}</td>
                        <td>{t('Committees.TableScientific.row1.col2')}</td>
                    </tr>
                    <tr>
                        <td>{t('Committees.TableScientific.row2.col1')}</td>
                        <td>{t('Committees.TableScientific.row2.col2')}</td>
                    </tr>
                    <tr>
                        <td>{t('Committees.TableScientific.row3.col1')}</td>
                        <td>{t('Committees.TableScientific.row3.col2')}</td>
                    </tr>
                    <tr>
                        <td>{t('Committees.TableScientific.row4.col1')}</td>
                        <td>{t('Committees.TableScientific.row4.col2')}</td>
                    </tr>
                    <tr>
                        <td>{t('Committees.TableScientific.row6.col1')}</td>
                        <td>{t('Committees.TableScientific.row6.col2')}</td>
                    </tr>
                    <tr>
                        <td>{t('Committees.TableScientific.row7.col1')}</td>
                        <td>{t('Committees.TableScientific.row7.col2')}</td>
                    </tr>
                    <tr>
                        <td>{t('Committees.TableScientific.row8.col1')}</td>
                        <td>{t('Committees.TableScientific.row8.col2')}</td>
                    </tr>
                    <tr>
                        <td>{t('Committees.TableScientific.row9.col1')}</td>
                        <td>{t('Committees.TableScientific.row9.col2')}</td>
                    </tr>
                    <tr>
                        <td>{t('Committees.TableScientific.row10.col1')}</td>
                        <td>{t('Committees.TableScientific.row10.col2')}</td>
                    </tr>
                    <tr>
                        <td>{t('Committees.TableScientific.row11.col1')}</td>
                        <td>{t('Committees.TableScientific.row11.col2')}</td>
                    </tr>
                    <tr>
                        <td>{t('Committees.TableScientific.row12.col1')}</td>
                        <td>{t('Committees.TableScientific.row12.col2')}</td>
                    </tr>
                    <tr>
                        <td>{t('Committees.TableScientific.row13.col1')}</td>
                        <td>{t('Committees.TableScientific.row13.col2')}</td>
                    </tr>
                    <tr>
                        <td>{t('Committees.TableScientific.row14.col1')}</td>
                        <td>{t('Committees.TableScientific.row14.col2')}</td>
                    </tr>
                    <tr>
                        <td>{t('Committees.TableScientific.row15.col1')}</td>
                        <td>{t('Committees.TableScientific.row15.col2')}</td>
                    </tr>
                    <tr>
                        <td>{t('Committees.TableScientific.row16.col1')}</td>
                        <td>{t('Committees.TableScientific.row16.col2')}</td>
                    </tr>
                    <tr>
                        <td>{t('Committees.TableScientific.row17.col1')}</td>
                        <td>{t('Committees.TableScientific.row17.col2')}</td>
                    </tr>
                    <tr>
                        <td>{t('Committees.TableScientific.row18.col1')}</td>
                        <td>{t('Committees.TableScientific.row18.col2')}</td>
                    </tr>
                </tbody>
            </table>
            <Typography sx={{
                fontWeight: 'bold',
                color: '#c00000'
            }}>{t("Committees.Alphabetical")}</Typography>
        </div>
    )
}

export default TableScientific
