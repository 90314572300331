import { useState } from "react";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
// import Container from "@mui/material/Container";
import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import NavDrawer from "./NavDrawer";
import { useTranslation } from "react-i18next";
import logo from './../../assets/Logo/logo.png'
import TransLang from "./TransLang"

import './Nav.css'
import SelectSmall from "./SelectSmall";
function NavBar() {
    const { t } = useTranslation()
    const [drawer, setDrawer] = useState(false);
    return (
        <>
            <AppBar position="static" elevation={0}
                style={{
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    // backgroundColor: "rgb(104 12 23 / 90%)",
                    padding: "10px"
                }}>
                <>
                    <Toolbar disableGutters>

                        <Link
                            to="/"
                            style={{
                                color: "#000",
                                textDecoration: "none",
                            }}
                        >
                            <img src={logo} height={50} alt="logo" className="nav-logo" ></img>
                        </Link>
                        <Box
                            sx={{
                                flexGrow: 1,
                                gap: 2,
                                marginInlineStart: 1,
                                display: { md: "flex", xs: "none" },
                                alignItems: "center",
                                justifyContent: 'flex-start'
                            }}>
                            <Link
                                to="/"
                                style={{
                                    color: "#000",
                                    textDecoration: "none",
                                    fontSize: 14,
                                    fontWeight: "bold",
                                }}>
                                {t('linksPages.home')}
                            </Link>
                            <Link
                                to="/General-information"
                                style={{
                                    color: "#000",
                                    textDecoration: "none",
                                    fontSize: 14,
                                    fontWeight: "bold",
                                }}>
                                {t('linksPages.GeneralInformation')}
                            </Link>
                            <Link
                                to="/Nubia-Egypt"
                                style={{
                                    color: "#000",
                                    textDecoration: "none",
                                    fontSize: 14,
                                    fontWeight: "bold",
                                }}>
                                {t('linksPages.Nubia-Egypt')}
                            </Link>
                            <Link
                                to="/Topics"
                                style={{
                                    color: "#000",
                                    textDecoration: "none",
                                    fontSize: 14,
                                    fontWeight: "bold",
                                }}>
                                {t('linksPages.Topics')}
                            </Link>
                            <Link
                                to="/Abstracts"
                                style={{
                                    color: "#000",
                                    textDecoration: "none",
                                    fontSize: 14,
                                    fontWeight: "bold",
                                }}>
                                {t('linksPages.Abstracts')}
                            </Link>
                            <SelectSmall />
                            <Link
                                to="/Committees"
                                style={{
                                    color: "#000",
                                    textDecoration: "none",
                                    fontSize: 14,
                                    fontWeight: "bold",
                                }}>
                                {t('linksPages.Committees')}
                            </Link>
                            <Link
                                to="/Accommodation"
                                style={{
                                    color: "#000",
                                    textDecoration: "none",
                                    fontSize: 14,
                                    fontWeight: "bold",
                                }}>
                                {t('linksPages.Accommodation')}
                            </Link>
                            <Link
                                to="/Contacts"
                                style={{
                                    color: "#000",
                                    textDecoration: "none",
                                    fontSize: 14,
                                    fontWeight: "bold",
                                }}>
                                {t('linksPages.Contacts')}
                            </Link>
                        </Box>

                        <Box sx={{ justifyContent: 'end', display: 'flex', gap: { md: '65px', xs: 1 }, width: { md: 'fit-content', xs: '100%' }, alignItems: 'center' }} >
                            <TransLang />
                            <Box
                                sx={{
                                    flexGrow: 0,
                                    display: { md: "none", xs: "flex" },
                                    justifyContent: "end",
                                }}>
                                <IconButton onClick={() => setDrawer(true)}>
                                    <MenuIcon sx={{ fontSize: '3rem', color: '#000' }} />
                                </IconButton>
                            </Box>
                        </Box>
                        {/* <TransLang /> */}
                    </Toolbar>
                </>
            </AppBar>
            <NavDrawer drawer={drawer} setDrawer={setDrawer} />
        </>
    );
}
export default NavBar;
