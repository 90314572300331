import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
// import i18next from 'i18next'

const ImportantNotes = () => {
    const { t } = useTranslation()
    // let lng = i18next.language
    return (
        <div>
            <Box>
                <Typography variant="h5" >{t("Registration.ImportantNotes.Name")}</Typography>
                <Box component={'ol'} >
                    <li>
                        <Typography>
                            {t("Registration.ImportantNotes.title1")}
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            {t("Registration.ImportantNotes.title2")}
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            {t("Registration.ImportantNotes.title3")}
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            {t("Registration.ImportantNotes.title4")}
                        </Typography>
                    </li>
                    {/* {
                        lng === 'ar' ?
                            <li>
                                <Typography>
                                    {t("Registration.ImportantNotes.title5")}
                                </Typography>
                            </li> : ''
                    } */}
                </Box>
            </Box>
        </div>
    )
}

export default ImportantNotes
