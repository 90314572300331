import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import './styleTable.css'
const Table = () => {
    const { t } = useTranslation()
    return (
        <div>
            <Box style={box} >
                <Typography sx={{ fontWeight: 'bold' }} >{t('GeneralInformation.ImportantDates')}</Typography>
                <table border="1" style={{ width: '100%', }}>
                    <thead>
                        <th>{t('GeneralInformation.Table.tableActivity')}</th>
                        <th>{t('GeneralInformation.Table.tableStart')}</th>
                        <th>{t('GeneralInformation.Table.tableDead')}</th>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{t('GeneralInformation.Table.row1.col1')}</td>
                            <td>{t('GeneralInformation.Table.row1.col2')}</td>
                            <td>{t('GeneralInformation.Table.row1.col3')}</td>
                        </tr>
                        <tr>
                            <td>{t('GeneralInformation.Table.row2.col1')}</td>
                            <td>{t('GeneralInformation.Table.row2.col2')}</td>
                            <td>{t('GeneralInformation.Table.row2.col3')}</td>
                        </tr>
                        <tr>
                            <td>{t('GeneralInformation.Table.row3.col1')}</td>
                            <td>{t('GeneralInformation.Table.row3.col2')}</td>
                            <td>{t('GeneralInformation.Table.row3.col3')}</td>
                        </tr>
                        <tr>
                            <td>{t('GeneralInformation.Table.row4.col1')}</td>
                            <td>{t('GeneralInformation.Table.row4.col2')}</td>
                            <td>{t('GeneralInformation.Table.row4.col3')}</td>
                        </tr>
                        <tr>
                            <td>{t('GeneralInformation.Table.row5.col1')}</td>
                            <td>{t('GeneralInformation.Table.row5.col2')}</td>
                            <td>{t('GeneralInformation.Table.row5.col3')}</td>
                        </tr>
                        <tr>
                            <td>{t('GeneralInformation.Table.row6.col1')}</td>
                            <td colspan="2" class="text-center">{t('GeneralInformation.Table.row6.col2')}</td>
                        </tr>
                        <tr>
                            <td>{t('GeneralInformation.Table.row7.col1')}</td>
                            <td colspan="2" class="text-center">{t('GeneralInformation.Table.row7.col2')}</td>
                        </tr>
                        <tr>
                            <td>{t('GeneralInformation.Table.row8.col1')}</td>
                            <td colspan="2" class="text-center">{t('GeneralInformation.Table.row8.col2')}</td>
                        </tr>
                        <tr>
                            <td>{t('GeneralInformation.Table.row5.col1')}</td>
                            <td>{t('GeneralInformation.Table.row5.col2')}</td>
                            <td>{t('GeneralInformation.Table.row5.col3')}</td>
                        </tr>
                        <tr>
                            <td>{t('GeneralInformation.Table.row10.col1')}</td>
                            <td colspan="2" class="text-center">{t('GeneralInformation.Table.row10.col2')}</td>
                        </tr>
                    </tbody>
                </table>
                <Box style={box} >
                    <Typography sx={{ textAlign: 'center', color: '#c00000', fontWeight: 'bold', }} variant='h4' >{t('GeneralInformation.Grants')}</Typography>
                    <Typography sx={{ fontSize: '20px' }} >{t('GeneralInformation.GrantsDescription')}</Typography>
                </Box>
            </Box>
        </div>
    )
}

const box = {
    display: 'flex',
    flexDirection: 'column',
    gap: '25px'
}

export default Table
