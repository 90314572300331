import NavBar from "./components/NavBar";
import { Routes, Route, useLocation } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Home from "./pages/Home";
import Footer from "./components/Footer/Footer";
import Cookies from 'js-cookie';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import GeneralPage from "./pages/GeneralPage";
import NubiaEgyptPage from "./pages/NubiaEgyptPage";
import TopicsPage from "./pages/TopicsPage";
import AbstractsPage from "./pages/AbstractsPage";
import CommitteesPage from "./pages/CommitteesPage";
import AccommodationPage from "./pages/AccommodationPage";
import ContactsPage from "./pages/ContactsPage";
import RegistrationPage from "./pages/RegistrationPage";
import RegistrationForm from "./pages/RegistrationForm";
import ScrollTo from './components/Ui/ScrollToTop';

const languages = [
    {
        code: 'ar',
        name: 'عربي',
        country_coode: 'sa',
        dir: 'rtl'
    },
    {
        code: 'en',
        name: 'English',
        country_coode: 'gb',
        dir: 'ltr'
    }
]
function App() {
    const currentLanguageCode = Cookies.get('i18next') || 'ar';
    const currentLanguage = useMemo(() => languages.find(l => l.code === currentLanguageCode), [currentLanguageCode])
    const { t } = useTranslation();
    useEffect(() => {

        document.dir = currentLanguage.dir || 'rtl';
        document.getElementsByTagName('html')[0].setAttribute('lang', currentLanguage.code || 'ar');
        // document.title = t('app_title'); 
    }, [currentLanguage, t]);
    const ScrollToTop = () => {
        const { pathname } = useLocation();

        useEffect(() => {
            window.scrollTo(0, 0);
        }, [pathname]);

        return null;
    };
    return (
        <div className="App">
            <CssBaseline />
            <NavBar />
            <ScrollToTop />
            <ScrollTo />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/General-information" element={<GeneralPage />} />
                <Route path="/Nubia-Egypt" element={<NubiaEgyptPage />} />
                <Route path="/Topics" element={<TopicsPage />} />
                <Route path="/Abstracts" element={<AbstractsPage />} />
                <Route path="/Registration" element={<RegistrationPage />} />
                <Route path="/RegistrationForm" element={<RegistrationForm />} />
                <Route path="/Committees" element={<CommitteesPage />} />
                <Route path="/Accommodation" element={<AccommodationPage />} />
                <Route path="/Contacts" element={<ContactsPage />} />
            </Routes>
            <Footer />
        </div>
    );
}

export default App;
