import React from 'react'
import NubiaEgypt from '../components/Nubia-Egypt/NubiaEgypt'

const NubiaEgyptPage = () => {
    return (
        <div>
            <NubiaEgypt />
        </div>
    )
}

export default NubiaEgyptPage
