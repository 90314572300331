import { Box, CardMedia, Typography } from '@mui/material'
import React from 'react'
import org from './../../assets/orgInfor/generalInfor.png'
import { useTranslation } from 'react-i18next'

const Organizers = () => {
    const { t } = useTranslation()

    return (
        <div style={box} >
            <Typography textAlign={'center'} sx={{ fontWeight: 'bold', color: '#c00000' }} variant='h5' >{t('GeneralInformation.title')}</Typography>
            <Box>
                <Typography sx={{ fontWeight: 'bold' }} variant='h5'>{t('GeneralInformation.Organizers')}</Typography>
                <Typography sx={{ fontWeight: 'bold', textAlign: 'center' }} >{t('GeneralInformation.OrganizersDescriptions')}</Typography>
                <CardMedia
                    component={'img'}
                    src={org}
                    alt='logo'

                    sx={{
                        margin: 'auto', width: '300px',
                        objectFit: 'contain',
                        height: '14pc',
                        mb: 2
                    }}
                />
            </Box>
        </div>
    )
}
const box = {
    display: 'flex',
    flexDirection: 'column',
    gap: '25px'
}
export default Organizers
