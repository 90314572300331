import * as React from "react";
import Drawer from "@mui/material/Drawer";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function NavDrawer({ setDrawer, drawer }) {
    const { t } = useTranslation()
    return (
        <div>
            <React.Fragment>
                <Drawer
                    anchor="left"
                    open={drawer}
                    onClose={() => setDrawer(false)}
                >
                    <Box
                        sx={{
                            width: 300,
                            gap: 3,
                            display: "flex",
                            flexDirection: "column",
                            textAlign: "right",
                            padding: "40px 20px",
                        }}>
                        <Link
                            to="/"
                            style={{
                                color: "inherit",
                                textDecoration: "none",
                                fontSize: 15,
                                fontWeight: "bold",
                            }}
                            onClick={() => setDrawer(false)}>
                            {t('linksPages.home')}
                        </Link>
                        <Link
                            to="/GeneralInformation"
                            style={{
                                color: "inherit",
                                textDecoration: "none",
                                fontSize: 15,
                                fontWeight: "bold",
                            }}
                            onClick={() => setDrawer(false)}>
                            {t('linksPages.GeneralInformation')}
                        </Link>
                        <Link
                            to="/Nubia-Egypt"
                            style={{
                                color: "inherit",
                                textDecoration: "none",
                                fontSize: 15,
                                fontWeight: "bold",
                            }}
                            onClick={() => setDrawer(false)}>
                            {t('linksPages.Nubia-Egypt')}
                        </Link>
                        <Link
                            to="/Topics"
                            style={{
                                color: "inherit",
                                textDecoration: "none",
                                fontSize: 15,
                                fontWeight: "bold",
                            }}
                            onClick={() => setDrawer(false)}>
                            {t('linksPages.Topics')}
                        </Link>
                        <Link
                            to="/Abstracts"
                            style={{
                                color: "inherit",
                                textDecoration: "none",
                                fontSize: 15,
                                fontWeight: "bold",
                            }}
                            onClick={() => setDrawer(false)}>
                            {t('linksPages.Abstracts')}
                        </Link>
                        <Link
                            to="/Registration"
                            style={{
                                color: "inherit",
                                textDecoration: "none",
                                fontSize: 15,
                                fontWeight: "bold",
                            }}
                            onClick={() => setDrawer(false)}>
                            {t('linksPages.Registration')}
                        </Link>
                        <Link
                            to="/RegistrationForm"
                            style={{
                                color: "inherit",
                                textDecoration: "none",
                                fontSize: 15,
                                fontWeight: "bold",
                            }}
                            onClick={() => setDrawer(false)}>
                            {t('linksPages.RegistrationForm')}
                        </Link>
                        <Link
                            to="/Committees"
                            style={{
                                color: "inherit",
                                textDecoration: "none",
                                fontSize: 15,
                                fontWeight: "bold",
                            }}
                            onClick={() => setDrawer(false)}>
                            {t('linksPages.Committees')}
                        </Link>
                        <Link
                            to="/Accommodation"
                            style={{
                                color: "inherit",
                                textDecoration: "none",
                                fontSize: 15,
                                fontWeight: "bold",
                            }}
                            onClick={() => setDrawer(false)}>
                            {t('linksPages.Accommodation')}
                        </Link>
                        <Link
                            to="/Contacts"
                            style={{
                                color: "inherit",
                                textDecoration: "none",
                                fontSize: 15,
                                fontWeight: "bold",
                            }}
                            onClick={() => setDrawer(false)}>
                            {t('linksPages.Contacts')}
                        </Link>

                    </Box>
                </Drawer>
            </React.Fragment>
        </div>
    );
}
