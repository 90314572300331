import { Box, Container, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const Abstracts = () => {
    const { t } = useTranslation();
    return (
        <div>
            <Container>
                <Box>
                    <Typography
                        variant="h4"
                        sx={{ textAlign: "center", fontWeight: "bold", color: "#c00000" }}
                    >
                        {t("Abstracts.Name")}
                    </Typography>
                    <Box component={"ol"}>
                        <li>
                            <Typography variant="h5" >{t("Abstracts.List.title1")}</Typography>
                            <Typography>{t("Abstracts.List.Description1")}</Typography>
                        </li>
                        <li>
                            <Typography variant="h5" >{t("Abstracts.List.title2")}</Typography>
                            <Box component={'ul'} >
                                <li>
                                    <Typography>
                                        <strong>{t("Abstracts.List.Desc2ListChild.title1")}</strong>
                                        {t("Abstracts.List.Desc2ListChild.Description1")}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <strong>{t("Abstracts.List.Desc2ListChild.title2")}</strong>
                                        {t("Abstracts.List.Desc2ListChild.Description2")}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <strong>{t("Abstracts.List.Desc2ListChild.title3")}</strong>
                                        {t("Abstracts.List.Desc2ListChild.Description3")}
                                        <a href="mailto:registration@nubia-2024.com">registration@nubia-2024.com </a>
                                        &
                                        <a href="mailto:Torab.magdy@gmail.com"> Torab.magdy@gmail.com</a>
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <strong>{t("Abstracts.List.Desc2ListChild.title4")}</strong>
                                        {t("Abstracts.List.Desc2ListChild.Description4")}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <strong>{t("Abstracts.List.Desc2ListChild.title5")}</strong>
                                        {t("Abstracts.List.Desc2ListChild.Description5")}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <strong>{t("Abstracts.List.Desc2ListChild.title6")}</strong>
                                        {t("Abstracts.List.Desc2ListChild.Description6")}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <strong>{t("Abstracts.List.Desc2ListChild.title7")}</strong>
                                        {t("Abstracts.List.Desc2ListChild.Description7")}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <strong>{t("Abstracts.List.Desc2ListChild.title8")}</strong>
                                        {t("Abstracts.List.Desc2ListChild.Description8")}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <strong>{t("Abstracts.List.Desc2ListChild.title9")}</strong>
                                        {t("Abstracts.List.Desc2ListChild.Description9")}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <strong>{t("Abstracts.List.Desc2ListChild.title10")}</strong>
                                        {t("Abstracts.List.Desc2ListChild.Description10")}
                                    </Typography>
                                </li>
                            </Box>
                        </li>
                        <li>
                            <Typography variant="h5" >{t("Abstracts.List.title3")}</Typography>
                            <Typography>{t("Abstracts.List.Description3")}</Typography>
                        </li>
                        <li>
                            <Typography variant="h5" >{t("Abstracts.List.title4")}</Typography>
                            <Typography>{t("Abstracts.List.Description4")}</Typography>
                        </li>
                        <li>
                            <Typography variant="h5" >{t("Abstracts.List.title5")}</Typography>
                            <Typography>{t("Abstracts.List.Description5")}</Typography>
                        </li>
                        <li>
                            <Typography variant="h5" >{t("Abstracts.List.title6")}</Typography>
                            {/* <Typography>{t("Abstracts.List.Description6")}</Typography> */}
                            <a href="mailto:registration@nubia-2024.com">registration@nubia-2024.com </a>
                            &
                            <a href="mailto:Torab.magdy@gmail.com"> Torab.magdy@gmail.com</a>
                        </li>
                        <li>
                            <Typography variant="h5" >{t("Abstracts.List.title7")}</Typography>
                            <Typography>{t("Abstracts.List.Description7")}</Typography>
                        </li>
                    </Box>
                </Box>
            </Container>
        </div>
    );
};

export default Abstracts;
