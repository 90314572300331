import React from 'react'
import Topics from '../components/Topics/Topics'
import Slider from "../components/Slider/Slider";
import slide_1 from './../assets/Slider/26.jpg'
import slide_2 from './../assets/Slider/14.jpg'
import slide_3 from './../assets/Slider/22.jpg'
import slide_4 from './../assets/Slider/24.jpg'
import slide_5 from './../assets/Slider/5.jpg'
const TopicsPage = () => {
    return (
        <>
            <Slider
                path_1={slide_1}
                path_2={slide_2}
                path_3={slide_3}
                path_4={slide_4}
                path_5={slide_5}
            />
            <div style={{ padding: '30px 0' }} >
                <Topics />
            </div>
        </>
    )
}

export default TopicsPage
