import React from 'react'
import { useTranslation } from 'react-i18next'
const TableOrganizing = () => {
    const { t } = useTranslation()
    return (
        <div>
            <table border="1" style={{ width: '100%', }}>
                <thead>
                    <th>{t('Committees.TableOrganization.Name')}</th>
                    <th>{t('Committees.TableOrganization.Institution')}</th>
                    <th>{t('Committees.TableOrganization.SymposiumPosition')}</th>
                </thead>
                <tbody>
                    <tr>
                        <td>{t('Committees.TableOrganization.row1.col1')}</td>
                        <td>{t('Committees.TableOrganization.row1.col2')}</td>
                        <td>{t('Committees.TableOrganization.row1.col3')}</td>
                    </tr>
                    <tr>
                        <td>{t('Committees.TableOrganization.row3.col1')}</td>
                        <td>{t('Committees.TableOrganization.row3.col2')}</td>
                        <td>{t('Committees.TableOrganization.row3.col3')}</td>
                    </tr>
                    <tr>
                        <td>{t('Committees.TableOrganization.row4.col1')}</td>
                        <td>{t('Committees.TableOrganization.row4.col2')}</td>
                        <td>{t('Committees.TableOrganization.row4.col3')}</td>
                    </tr>
                    {/* <tr>
                        <td>{t('Committees.TableOrganization.row5.col1')}</td>
                        <td>{t('Committees.TableOrganization.row5.col2')}</td>
                        <td>{t('Committees.TableOrganization.row5.col3')}</td>
                    </tr> */}
                    <tr>
                        <td>{t('Committees.TableOrganization.row7.col1')}</td>
                        <td>{t('Committees.TableOrganization.row7.col2')}</td>
                        <td>{t('Committees.TableOrganization.row7.col3')}</td>
                    </tr>
                    <tr>
                        <td>{t('Committees.TableOrganization.row6.col1')}</td>
                        <td>{t('Committees.TableOrganization.row6.col2')}</td>
                        <td>{t('Committees.TableOrganization.row6.col3')}</td>
                    </tr>
                    <tr>
                        <td>{t('Committees.TableOrganization.row11.col1')}</td>
                        <td>{t('Committees.TableOrganization.row11.col2')}</td>
                        <td>{t('Committees.TableOrganization.row11.col3')}</td>
                    </tr>
                    {/* <tr>
                        <td>{t('Committees.TableOrganization.row8.col1')}</td>
                        <td>{t('Committees.TableOrganization.row8.col2')}</td>
                        <td>{t('Committees.TableOrganization.row8.col3')}</td>
                    </tr>
                    <tr>
                        <td>{t('Committees.TableOrganization.row9.col1')}</td>
                        <td>{t('Committees.TableOrganization.row9.col2')}</td>
                        <td>{t('Committees.TableOrganization.row9.col3')}</td>
                    </tr> */}
                    <tr>
                        <td>{t('Committees.TableOrganization.row10.col1')}</td>
                        <td>{t('Committees.TableOrganization.row10.col2')}</td>
                        <td>{t('Committees.TableOrganization.row10.col3')}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default TableOrganizing
