import { Box, Container, Stack, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ExampleDoc from './../assets/Registration form.docx'
import './FormBtn.css'
const RegistrationForm = () => {
    const { t } = useTranslation()
    return (
        <div style={{ padding: '25px 0' }} >
            <Container>
                <Box>
                    <Stack textAlign={'center'} fontWeight={'bold'} gap={3} mb={2} >
                        <Typography variant='h4' sx={{ color: '#c00000' }} >{t("RegistrationForm.Name")}</Typography>
                        <Typography variant='h5' sx={{ color: '#002060' }} >{t("RegistrationForm.start")}</Typography>
                    </Stack>
                    <ol>
                        <li style={{ fontWeight: 'bold' }} >
                            <Typography sx={{ fontWeight: 'bold', }} variant='h6' >{t("RegistrationForm.step1")}</Typography>
                        </li>
                        <li style={{ fontWeight: 'bold' }} >
                            <Typography sx={{ fontWeight: 'bold', }} variant='h6' >{t("RegistrationForm.step2")}</Typography>
                        </li>
                        <li style={{ fontWeight: 'bold' }} >
                            <Typography sx={{ fontWeight: 'bold', }} variant='h6' >{t("RegistrationForm.step3")}</Typography>
                        </li>
                    </ol>
                </Box>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: { md: 'row', xs: 'column' },
                    gap: '30px',
                    mt: '45px',
                    mb: '45px'
                }} >
                    <a href={ExampleDoc}
                        download="MyExampleDoc"
                        target='_blank'
                        rel="noreferrer">
                        <div class="wrap">
                            <button className="button download" style={{
                                backgroundColor: '#002060'
                            }} >{t("RegistrationForm.download")}</button>
                        </div>
                    </a>
                    <a href='mailto:registration@nubia-2024.com'
                        download="MyExampleDoc"
                        target='_blank'
                        rel="noreferrer">
                        <div class="wrap">
                            <button className="button upload" style={{
                                backgroundColor: '#c00000'
                            }} >{t("RegistrationForm.upload")}</button>
                        </div>
                    </a>
                </Box>
            </Container>
        </div>
    )
}

export default RegistrationForm
