import { Box, CardMedia, Container, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import SectionVenue from './SectionVenue'
import path1 from './../../assets/attachments/هيسا.jpg'
import path2 from './../../assets/attachments/هيسا2.jpg'
import path3 from './../../assets/attachments/بيجة.jpg'
import path4 from './../../assets/attachments/بيجة2.jpg'
import path5 from './../../assets/attachments/ايجلكا.jpg'
import path6 from './../../assets/attachments/أيجلكا 2.jpg'
import path7 from './../../assets/attachments/تنجار 1.jpg'
import path8 from './../../assets/attachments/تنجار 2.jpg'
import path9 from './../../assets/attachments/غرب سهيل.jpg'
import path10 from './../../assets/attachments/غرب سهيل - بربر.jpg'
import path11 from './../../assets/attachments/الفنتين.jpg'
import path12 from './../../assets/attachments/الفنتين 2.jpg'
import map from './../../assets/Home/FirstCataract.jpg'
import map1 from './../../assets/orgInfor/genMap.png'
import Slider from "../Slider/Slider";
import slide_1 from './../../assets/attachments/معبد فيلة 2.jpg'
import slide_2 from './../../assets/attachments/معبد فيلة.jpg'
import slide_3 from './../../assets/attachments/لوحة المجاعة.jpeg'
import slide_4 from './../../assets/attachments/إلفنتين 3 مقياس النيل.jpg'
import slide_5 from './../../assets/attachments/الفنتين 2.jpg'
const NubiaEgypt = () => {
    const { t } = useTranslation()

    return (
        <div  >
            <Slider
                path_1={slide_1}
                path_2={slide_2}
                path_3={slide_3}
                path_4={slide_4}
                path_5={slide_5}
            />
            <Box style={{ padding: '25px 0' }}>
                <Container>
                    <Typography textAlign={'center'} sx={{ fontWeight: 'bold', color: '#c00000' }}
                        variant='h5' >{t('GeneralInformation.VenueTitle')}</Typography>
                    <Box style={box} textAlign={'center'} >
                        <Box display={'flex'} justifyContent={'space-between'} sx={{ flexDirection: { md: 'row', xs: 'column' } }} >
                            <div>
                                <CardMedia
                                    component={'img'}
                                    src={map1}
                                    alt='logo'

                                    sx={{
                                        margin: 'auto', width: { md: '500px', xs: '100%' },
                                        height: '30pc',
                                        objectFit: 'fill',
                                        mb: 2,
                                        mt: 2
                                    }}
                                />
                                <Typography sx={{ fontWeight: 'bold' }} >{t('Nubia-Egypt.map1')}</Typography>
                            </div>
                            <div>
                                <CardMedia
                                    component={'img'}
                                    src={map}
                                    alt='logo'
                                    sx={{
                                        margin: 'auto', width: { md: '500px', xs: '100%' },
                                        height: '30pc',
                                        objectFit: 'fill',
                                        mb: 2,
                                        mt: 2
                                    }}
                                />
                                {/* <Typography sx={{ fontWeight: 'bold', textAlign: 'center' }} >{t('Nubia-Egypt.title5')}</Typography> */}
                            </div>
                        </Box>
                    </Box>
                    <Box style={box} >
                        <SectionVenue
                            title={t('Nubia-Egypt.title1')}
                            Descriptions={t('Nubia-Egypt.Description1')}
                            path1={path1}
                            path2={path2}
                            SupDescription1={t('Nubia-Egypt.SupDescription1')}
                        />
                        <SectionVenue
                            title={t('Nubia-Egypt.title2')}
                            Descriptions={t('Nubia-Egypt.Description2')}
                            path1={path3}
                            path2={path4}
                        />
                        <SectionVenue
                            title={t('Nubia-Egypt.title3')}
                            Descriptions={t('Nubia-Egypt.Description3')}
                            path1={path5}
                            path2={path6}
                        />
                        <SectionVenue
                            title={t('Nubia-Egypt.title4')}
                            Descriptions={t('Nubia-Egypt.Description4')}
                            path1={path7}
                            path2={path8}
                        />
                        <SectionVenue
                            title={t('Nubia-Egypt.title5')}
                            Descriptions={t('Nubia-Egypt.Description5')}
                            path1={path9}
                            path2={path10}
                        />
                        <SectionVenue
                            title={t('Nubia-Egypt.title6')}
                            Descriptions={t('Nubia-Egypt.Description6')}
                            path1={path11}
                            path2={path12}
                        />

                    </Box>
                </Container>
            </Box>
        </div>
    )
}
const box = {
    display: 'flex',
    flexDirection: 'column',
    gap: '25px'
}
export default NubiaEgypt
