import { Box, Container, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import './Topics.css'
const Topics = () => {
    const { t } = useTranslation()
    return (
        <div>
            <Box>
                <Container>
                    <Box>
                        <Typography variant='h4'
                            sx={{
                                textAlign: "center",
                                fontWeight: "bold",
                                color: "#c00000"
                            }} >
                            {t("Topics.Name")}
                        </Typography>
                        <Box component={'ol'} >
                            <li className='my-li' >{t('Topics.title1')}</li>
                            <li className='my-li' >{t('Topics.title2')}</li>
                            <li className='my-li' >{t('Topics.title3')}</li>
                            <li className='my-li' >{t('Topics.title4')}</li>
                            <li className='my-li' >{t('Topics.title5')}</li>
                            <li className='my-li' >{t('Topics.title6')}</li>
                            {/* <li className='my-li' >{t('Topics.title7')}</li> */}
                        </Box>
                    </Box>
                </Container>
            </Box>
        </div>
    )
}

export default Topics
