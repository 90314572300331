import { Box, Container, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Table from './Table'
import ImportantNotes from './ImportantNotes'
import Nonrefundable from './Nonrefundable'
import Cancellation from './Cancellation'
import Accommodation from './Accommodation'
import FullPac from './FullPac'
import RegistrationForm from '../../pages/RegistrationForm'

const Registration = () => {
    const { t } = useTranslation()
    return (
        <div>
            <Container>
                <Box >
                    <Typography variant='h4' sx={{ textAlign: 'center', fontWeight: 'bold', color: '#c00000' }} >
                        {t("Registration.Name")}
                    </Typography>
                    <RegistrationForm />
                    <Typography sx={{ fontWeight: 'bold' }} >
                        {t("Registration.RegistrationFees")}
                    </Typography>
                    <Table />
                </Box>
                <ImportantNotes />
                <FullPac />
                <Nonrefundable />
                <Cancellation />
                <Accommodation />
                <Typography sx={{ fontWeight: 'bold' }} >{t("Registration.HowToPay")}</Typography>
                <Box textAlign={'center'} >
                    <a href="mailto:registration@nubia-2024.com">registration@nubia-2024.com </a>
                    &
                    <a href="mailto:Torab.magdy@gmail.com"> Torab.magdy@gmail.com</a>
                </Box>
            </Container>
        </div >
    )
}

export default Registration
