import { Box, CardMedia } from '@mui/material'
import React from 'react'
import logo from './../../assets/Logo/logo.png'
const CardLogo = () => {
    return (
        <div>
            <Box>
                <CardMedia
                    component={'img'}
                    src={logo}
                    alt='logo'

                    sx={{
                        margin: 'auto', width: 'fit-content',
                        height: '14pc',
                        mb: 2
                    }}
                />
            </Box>
        </div>
    )
}
export default CardLogo
